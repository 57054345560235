import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import filterMarkdownByLang from 'utils/filterMarkdownByLang';

const RodoPage = ({ data, pageContext }) => {
  const lang = pageContext.language;
  const contentInCurrentLang = filterMarkdownByLang(lang, data);

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{ __html: contentInCurrentLang.html }}
      />
    </div>
  );
};

RodoPage.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  pageContext: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export const pageQuery = graphql`
  query Rodos {
    allMarkdownRemark(filter:{
        frontmatter: {
            name: {
                eq: "rodo"
            }
        }
    }) {
        nodes {
            html
            frontmatter {
                lang
            }
        }
    }
  }
`;

export default RodoPage;
